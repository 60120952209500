import React from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

import ToFactory from "components/Factory/ToFactory";

const LogsTab = ({ logs }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const overviewLogs = [
    {
      left: "Address",
      column: ({ row }) => {
        return <ToFactory row={{ to: row.address }} />;
      },
    },
    {
      left: "Topic",
      column: ({ row }) => {
        const topics = JSON.parse(row.topics);
        return topics.map((item, index) => {
          return (
            <Box sx={{ display: "flex", alignItems: "center" }} key={"topic" + index}>
              <Typography sx={{ fontFamily: "Roboto Mono" }} variant="caption">
                <span style={{ color: "black", fontWeight: "bold" }}>{index}</span> - {item}
              </Typography>
            </Box>
          );
        });
      },
    },
    {
      left: "Data",
      column: ({ row }) => {
        return row.data;
      },
    },
  ];

  return (
    <React.Fragment>
      {logs.length === 0 && (
        <Box p={3}>
          <Typography>{t("There are no logs.")}</Typography>
        </Box>
      )}
      {logs.map((log, index) => {
        return (
          <Box key={"GridRowLogWrapper" + index}>
            <Box sx={{ p: 2 }}>
              <Typography variant="h4">
                {t("Topic")} #{log.log_index}
              </Typography>
            </Box>
            {overviewLogs.map((item, inner) => {
              return (
                <React.Fragment key={inner + "GridRowLog" + index}>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sm={2}
                      md={2}
                      lg={2}
                      xl={2}
                      sx={{
                        padding: theme.spacing(1, 2),
                        wordBreak: "break-all",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="body2">{item.left}</Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={10}
                      md={10}
                      lg={10}
                      xl={10}
                      sx={{
                        padding: theme.spacing(1, 2),
                        wordBreak: "break-all",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="caption">
                        {!item.column && ""}
                        {item.column && typeof item.column === "function" && <item.column row={log} />}
                      </Typography>
                    </Grid>
                  </Grid>
                </React.Fragment>
              );
            })}
          </Box>
        );
      })}
    </React.Fragment>
  );
};

export default LogsTab;

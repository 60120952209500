import React from "react";
import { Card, Container, Divider, Typography, Box, Tabs, Tab } from "@mui/material";
import axios from "axios";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

import OverviewTab from "components/Transaction/OverviewTab";
import Page from "components/Common/Page";
import FullPageLoader from "components/Common/FullPageLoader";
import FullPage404 from "components/Common/FullPage404";
import GlobalContext from "context/GlobalContext";
import LogsTab from "components/Transaction/LogsTab";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100% - 100px)",
  },
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
};

const tabTitles = ["Overview", "Logs"];

const AddressDetail = ({ history, match }) => {
  const classes = useStyles();
  const { web3 } = React.useContext(GlobalContext);
  const [info, setInfo] = React.useState(false);
  const [logs, setLogs] = React.useState(false);
  const [tab, setTab] = React.useState(0);
  const { t } = useTranslation();
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const getTx = React.useCallback(async () => {
    const text = match.params.hash;
    try {
      const isHex = await web3.utils.isHex(text);
      if (!isHex) {
        return setInfo(undefined);
      }

      const isHexStrict = await web3.utils.isHexStrict(text);

      if (!isHexStrict) {
        return history.push("/hash/0x" + text);
      }

      const body = { hash: text };
      const {
        data: { data, logs },
      } = await axios.post(process.env.REACT_APP_HOST + "/api/v1/transaction", body);

      if (!data) {
        return setInfo(undefined);
      }
      setLogs(logs);
      setInfo(data);
    } catch (e) {
      return setInfo(undefined);
    }
    //eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    getTx();
  }, [getTx]);

  return (
    <Page title="Transaction" className={classes.root}>
      {info && logs && (
        <Container className={classes.container} maxWidth={"lg"}>
          <Box display="flex" flexDirection="row" alignItems="center" mb={2}>
            <Typography fontWeight="normal" variant="h4" mr={0.5}>
              {t("Transaction Detail")}
            </Typography>
            <Typography variant="body2">{match.params.address}</Typography>
          </Box>
          <Card>
            <Tabs value={tab} onChange={(_, v) => setTab(v)} aria-label="basic tabs">
              {tabTitles.map((title, index) => {
                return <Tab key={index + "tab"} label={t(title)} {...a11yProps[index]} />;
              })}
            </Tabs>
            <TabPanel value={tab} index={0}>
              <Divider />
              <OverviewTab info={info} logs={logs} />
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <LogsTab info={info} logs={logs} />
            </TabPanel>
          </Card>
        </Container>
      )}
      {info === undefined && <FullPage404 />}
      {info === false && <FullPageLoader />}
    </Page>
  );
};

export default AddressDetail;

import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Container } from "@mui/material";

import axios from "axios";

import Page from "components/Common/Page";
import BlockList from "components/Dashboard/BlockList";
import TransactionList from "components/Dashboard/TransactionList";
import Ribbon from "components/Dashboard/Ribbon";
import ChainInfo from "components/Dashboard/ChainInfo";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

// web3.eth
//   .getTransactionReceipt("0x58d9f6df7b8cef2574d0fdfa45012e2be8d2ceaddbba9452ea0c0f87d30a7df4")
//   .then((e) => console.log(e));

// web3.eth
//   .getTransaction("0x58d9f6df7b8cef2574d0fdfa45012e2be8d2ceaddbba9452ea0c0f87d30a7df4")
//   .then((e) => console.log(e));

// web3.eth.getCode("0xf0f9e1789f9cB334d3bD4BBc81219c99a54A2657").then((e) => console.log(e));

const LoginView = () => {
  const classes = useStyles();
  const [blocks, setBlocks] = React.useState([{}, {}, {}, {}, {}]);
  const [transactions, setTransactions] = React.useState([{}, {}, {}, {}, {}]);
  const [init, setInit] = React.useState(false);
  const [chainInfo, setChainInfo] = React.useState(false);

  const initialize = async () => {
    const {
      data: { blocks, transactions, txCount },
    } = await axios.post(process.env.REACT_APP_HOST + "/api/v1/dashboard");
    const { data } = await axios.post(process.env.REACT_APP_HOST + "/api/v1/marketcap");
    setBlocks(blocks);
    setTransactions(transactions);
    setInit(true);
    setChainInfo({ txCount, ...data[0] });
  };

  React.useEffect(() => {
    initialize();
  }, []);

  return (
    <Page title="Dashboard">
      <Ribbon />
      <Container className={classes.root} maxWidth={"xl"}>
        <ChainInfo chainInfo={chainInfo} />
        <Grid container alignItems="center" justifyContent="space-evenly">
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <BlockList init={init} blocks={blocks} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <TransactionList init={init} transactions={transactions} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default LoginView;

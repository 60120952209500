import React from "react";
import { useSnackbar } from "notistack";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IconButton } from "@mui/material";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { CopyToClipboard } from "react-copy-to-clipboard";

const FromFactory = ({ row, className, copy = false }) => {
  const parmas = useParams();
  const address = parmas.address ? parmas.address.toLowerCase() : "";
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const copyOnClick = () => {
    return enqueueSnackbar(t("클립보드에 복사되었습니다."), { variant: "success" });
  };

  if (row.miner) {
    return (
      <Link
        style={{
          fontFamily: "Roboto Mono",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
        className={className}
        to={"/address/" + row.miner}
      >
        {row.miner}
      </Link>
    );
  }

  if (row.from.toLowerCase() === address) {
    return <span style={{ fontFamily: "Roboto Mono" }}>{row.from}</span>;
  } else {
    return (
      <React.Fragment>
        <Link
          style={{
            fontFamily: "Roboto Mono",
          }}
          className={className}
          to={"/address/" + row.from}
        >
          {row.from}
        </Link>
        {copy && (
          <CopyToClipboard onCopy={copyOnClick} text={row.from}>
            <IconButton size="small" sx={{ ml: 0.25 }}>
              <ContentCopyOutlinedIcon sx={{ fontSize: 12 }} />
            </IconButton>
          </CopyToClipboard>
        )}
      </React.Fragment>
    );
  }
};

export default FromFactory;

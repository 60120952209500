import React from "react";
import { Box, Container, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
// import AbstractShape from "components/Svg/abstract-shape.svg";
import AbstractShape from "resources/images/shape_ribbon.png";
import SearchBox from "components/Common/SearchBox";

const Ribbon = () => {
  const classes = useStyles();

  // const searchOnSubmit = (e) => {
  //   e.preventDefault();
  //   history.push("/");
  // };

  return (
    <Box className={classes.ribbon}>
      <Container className={classes.root} maxWidth="xl">
        <Box p={1} mb={1}>
          <Typography className={classes.title} variant="h4">
            {process.env.REACT_APP_PROJECT_NAME.toUpperCase()} BLOCKCHAIN EXPLORER
          </Typography>
        </Box>
        <SearchBox />
      </Container>
    </Box>
  );
};
const useStyles = makeStyles((theme) => ({
  root: {
    displa: "flex",
    flexDirection: "column",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  ribbon: {
    backgroundImage: `url(${AbstractShape})`,
    padding: theme.spacing(3),
    height: 270,
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  title: {
    color: theme.palette.background.default,
  },
}));

export default Ribbon;

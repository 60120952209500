import React from "react";
import { Link } from "react-router-dom";
import { Divider, Grid, Typography, useTheme, useMediaQuery } from "@mui/material";
import moment from "moment";
import numeral from "numeral";

import BlockFactory from "components/Factory/BlockFactory";
import FromFactory from "components/Factory/FromFactory";

const Overview = ({ info }) => {
  const theme = useTheme();
  const smView = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const overview = [
    {
      left: "Block Height",
      column: ({ row }) => {
        return <BlockFactory span row={row} />;
      },
    },
    {
      left: "Timestamp",
      column: ({ row }) => {
        const utcOffset = moment().utcOffset() / 60;
        return (
          <span>
            {moment(Number(`${row.timestamp}000`)).format("YYYY-MM-DD HH:mm:ss")} ({utcOffset < 0 ? "-" : "+"}
            {utcOffset} UTC)
          </span>
        );
      },
    },
    {
      left: "Transactions",
      column: ({ row }) => {
        const text = `${row.transactions.length} transactions `;
        return (
          <span>
            {row.transactions.length === 0 && <span>{text}</span>}
            {row.transactions.length > 0 && <Link to={`/txs?block=${row.number}`}>{text}</Link>}
            <span>in this block</span>
          </span>
        );
      },
    },
    {
      left: "Uncles",
      column: ({ row }) => {
        return <span>{numeral(row.uncle_count).format("0,0.[00000000]")}</span>;
      },
    },
    {
      left: "Mined by",
      column: ({ row }) => {
        return <FromFactory row={row} />;
      },
    },
    {
      left: "Gas Used",
      column: ({ row }) => {
        return <span>{numeral(row.gas_used).format("0,0.[00000000]")}</span>;
      },
    },
    {
      left: "Gas Limit",
      column: ({ row }) => {
        return <span>{numeral(row.gas_limit).format("0,0.[00000000]")}</span>;
      },
    },
    {
      left: "Difficulty",
      column: ({ row }) => {
        return <span>{numeral(row.difficulty).format("0,0.[00000000]")}</span>;
      },
    },
    {
      left: "Total Difficulty",
      column: ({ row }) => {
        return <span>{numeral(row.total_difficulty).format("0,0.[00000000]")}</span>;
      },
    },
    {
      left: "Size",
      column: ({ row }) => {
        return <span>{numeral(row.size).format("0,0.[00000000]")} bytes</span>;
      },
    },
    {
      left: "Hash",
      column: ({ row }) => {
        return <span>{row.hash}</span>;
      },
    },
    {
      left: "Parent Hash",
      column: ({ row }) => {
        return <span>{row.parent_hash}</span>;
      },
    },
    {
      left: "Sha3Uncles",
      column: ({ row }) => {
        return <span>{row.sha3_uncles}</span>;
      },
    },
  ];

  return overview.map((item, index) => (
    <React.Fragment key={index + "GridRow"}>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={2}
          md={2}
          lg={2}
          xl={2}
          sx={{
            wordBreak: "break-all",
            padding: theme.spacing(smView ? 0 : 1, 2),
            height: smView ? 36 : 45,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography variant="body2">{item.left}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={10}
          lg={10}
          xl={10}
          sx={{
            wordBreak: "break-all",
            padding: theme.spacing(smView ? 0 : 1, 2),
            height: smView ? 36 : 45,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography variant="caption">
            {item.column === "" && "-"}
            {!item.column && ""}
            {item.column && typeof item.column === "string" && info[item.column]}
            {item.column && typeof item.column === "function" && <item.column row={info} />}
          </Typography>
        </Grid>
      </Grid>
      <Divider />
    </React.Fragment>
  ));
};

export default Overview;

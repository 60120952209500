import React from "react";
import { Container, IconButton, AppBar, Toolbar, Box, useMediaQuery, Button, Typography } from "@mui/material";

import { useHistory } from "react-router-dom";
import SearchBox from "components/Common/SearchBox";
import MetamaskIcon from "resources/images/metamask.png";
import MetamaskIconGrey from "resources/images/metamask_grey.png";
import ExplorerLogo from "resources/images/explorer_logo.png";
import GlobalContext from "context/GlobalContext";

const Header = () => {
  const { network } = React.useContext(GlobalContext);
  const history = useHistory();
  const smView = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const addNetwork = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: process.env.REACT_APP_CHAIN_ID_HEX }],
      });
      // return 값이 error 객체일 경우 해당 요청은 실패한 요청이다.
    } catch (e) {
      // MetaMask에 해당 네트워크가 없는 경우 발생하는 에러
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            // chainId: "0x" + Number(process.env.REACT_APP_CHAIN_ID).toString(16),
            chainId: "0x" + Number(process.env.REACT_APP_CHAIN_ID).toString(16),
            chainName: process.env.REACT_APP_COIN_NAME,
            nativeCurrency: {
              name: process.env.REACT_APP_COIN_NAME + " Mainnet",
              symbol: process.env.REACT_APP_COIN_TICKER.toUpperCase(), // 2-6 characters long
              decimals: 18,
            },
            // rpcUrls: [process.env.REACT_APP_HTTP_HOST],
            rpcUrls: [process.env.REACT_APP_HTTP_HOST],
            blockExplorerUrls: [process.env.REACT_APP_EXPLORER_HOST],
            iconUrls: [process.env.REACT_APP_SYMBOL_PATH],
          },
        ],
      });
    }
  };

  return (
    <AppBar enableColorOnDark sx={{ backgroundColor: "white" }} position="static">
      <Container maxWidth="xl">
        <Toolbar sx={{ justifyContent: "space-between" }} variant="dense">
          <IconButton
            onClick={() => history.push("/")}
            sx={{ p: 0 }}
            disableRipple={true}
            disableFocusRipple={true}
            aria-label="menu"
          >
            <img style={{ height: 37 }} src={ExplorerLogo} alt="explorer_logo" />
          </IconButton>
          {network !== process.env.REACT_APP_CHAIN_ID_HEX && (
            <Button onClick={addNetwork} disabled={!window.ethereum}>
              {window.ethereum ? (
                <Box display="flex" alignItems="center" justifyContent="center">
                  <img alt="metamask" style={{ width: 24, height: 24 }} src={MetamaskIcon} />
                  <Typography sx={{ ml: 1, fontSize: 13, display: { xs: "none", sm: "none", md: "block" } }}>
                    Add {process.env.REACT_APP_COIN_NAME} Network
                  </Typography>
                </Box>
              ) : (
                <Box display="flex" alignItems="center" justifyContent="center">
                  <img alt="metamask_gray" style={{ width: 24, height: 24 }} src={MetamaskIconGrey} />
                  <Typography sx={{ ml: 1, fontSize: 13, display: { xs: "none", sm: "none", md: "block" } }}>
                    NEED METAMASK
                  </Typography>
                </Box>
              )}
            </Button>
          )}
        </Toolbar>
        {history.location.pathname !== "/" && (
          <Toolbar disableGutters sx={{ justifyContent: "flex-end" }} variant="dense">
            <Box sx={{ width: smView ? "100%" : "50%", display: "flex", justifyContent: "flex-end" }}>
              <SearchBox size="small" />
            </Box>
          </Toolbar>
        )}
      </Container>
    </AppBar>
  );
};

export default Header;

import React from "react";
import { Link } from "react-router-dom";
import { IconButton } from "@mui/material";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

const HashFactory = ({ row, span, copy, className }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const copyOnClick = () => {
    return enqueueSnackbar(t("클립보드에 복사되었습니다."), { variant: "success" });
  };

  if (span) {
    return (
      <span style={{ wordBreak: "break-all" }}>
        <span style={{ fontFamily: "Roboto Mono" }}>{row.hash}</span>
        {copy && (
          <CopyToClipboard onCopy={copyOnClick} text={row.hash}>
            <IconButton size="small" sx={{ ml: 0.25 }}>
              <ContentCopyOutlinedIcon sx={{ fontSize: 12 }} />
            </IconButton>
          </CopyToClipboard>
        )}
      </span>
    );
  }

  return (
    <Link
      style={{ fontFamily: "Roboto Mono", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}
      className={className}
      to={"/tx/" + row.hash}
    >
      {row.hash}
    </Link>
  );
};

export default HashFactory;

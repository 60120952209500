import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ko from "resources/language/ko";

const resources = {
  ko,
};

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem("language") ? localStorage.getItem("language") : "ko",
});
export default i18n;

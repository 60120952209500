import { createTheme } from "@mui/material/styles";

import typography from "styles/theme/typography";
import shadows from "styles/theme/shadows";
import customColors from "styles/theme/colors";
// import { colors } from "@mui/material";

const theme = createTheme({
  palette: {
    // mode: "dark",
    background: {
      default: "#FAFAFA",
    },
    primary: {
      main: customColors[600],
      // main: "#3C2A57",
    },
    secondary: {
      main: customColors[300],
      // main: "#635590",
    },
  },
  typography,
  shadows,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "*": {
          boxSizing: "border-box",
          margin: 0,
          padding: 0,
          userSelect: "none",
          WebkitTapHighlightColor: "rgba(255, 255, 255, 0)",
          WebkitTouchCallout: "none",
        },
        input: {
          userSelect: "text",
        },
        html: {
          WebkitFontSmoothing: "antialiased",
          MozOsxFontSmoothing: "grayscale",
          height: "100%",
          width: "100%",
        },
        body: {
          height: "100%",
          width: "100%",
        },
        a: {
          textDecoration: "none",
        },
        "#root": {
          height: "100%",
          width: "100%",
        },
      },
    },
    MuiDialog: {
      paperWidthXs: {
        maxWidth: 280,
      },
    },
  },
});

// theme.palette.white = theme.palette.augmentColor({
//   main: "#000000",
// });

export default theme;

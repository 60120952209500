const primary = {
  50: "#ebeaf1",
  100: "#cccbde",
  200: "#acaac8",
  300: "#8d89b1",
  400: "#776ea0",
  main: "#8200ff", // contrast 3.83:1
  500: "#635590",
  600: "#8200ff",
  700: "#53437b",
  800: "#4b3a6f",
  900: "#3c2a57",
};

export default primary;

import React from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import numeral from "numeral";

import FromFactory from "components/Factory/FromFactory";
import ToFactory from "components/Factory/ToFactory";
import GlobalContext from "context/GlobalContext";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  hiddenText: {
    display: "block",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: 132,
    lineHeight: 1.5,
  },
}));

const TokenTransferred = ({ log, contract }) => {
  const { t } = useTranslation();
  const { web3 } = React.useContext(GlobalContext);
  const classes = useStyles();
  const commonInline = {
    fontSize: 12,
    lineHeight: 1.5,
    fontFamily: "Roboto Mono",
  };
  return log && contract ? (
    log.map((item, index) => {
      return (
        <Box
          key={index + "transfer"}
          display="flex"
          alignItems="center"
          flexWrap="wrap"
          component="span"
          sx={{ mb: 1.5, mt: 1 }}
        >
          <Box display="flex" mr={1}>
            <Typography sx={{ fontWeight: "bold", mr: 0.5, ...commonInline }}>{t("From")}</Typography>
            <FromFactory className={classes.hiddenText} row={{ from: item._from }} />
          </Box>
          <Box display="flex" mr={1}>
            <Typography sx={{ fontWeight: "bold", mr: 0.5, ...commonInline }}>{t("To")}</Typography>
            <ToFactory className={classes.hiddenText} row={{ to: item._to }} />
          </Box>
          <Box display="flex" flexWrap="wrap">
            <Typography sx={{ fontWeight: "bold", mr: 0.5, ...commonInline }}>{t("For")}</Typography>
            {item._type === "erc-20" && (
              <Typography sx={{ ...commonInline, mr: 0.5 }}>
                {numeral(web3.utils.fromWei(item._value)).format("0,0.[00000000]")}
              </Typography>
            )}
            {item._type === "erc-721" && (
              <React.Fragment>
                <Typography sx={{ ...commonInline, mr: 0.5 }}>ERC-721</Typography>
                <Typography sx={{ fontWeight: "bold", ...commonInline, mr: 0.5 }}>
                  Token ID [<Link to={`/token/${item.address}?a=${item._value}`}>{item._value}</Link>]
                </Typography>
              </React.Fragment>
            )}
            {item._type === "erc-1155" && (
              <React.Fragment>
                <Typography sx={{ ...commonInline, mr: 0.5 }}>ERC-1155</Typography>
                <Typography sx={{ fontWeight: "bold", ...commonInline, mr: 0.5 }}>
                  For {item._value.split("^")[1]} of Token ID [
                  <Link to={`/token/${item.address}?a=${item._value.split("^")[0]}`}>{item._value.split("^")[0]}</Link>]
                </Typography>
              </React.Fragment>
            )}
            <Link to={`/token/${item.address}`}>
              <Typography sx={{ ...commonInline, fontWeight: "bold" }}>
                {contract[index].ticker}({contract[index].name})
              </Typography>
            </Link>
          </Box>
        </Box>
      );
    })
  ) : (
    <div>...</div>
  );
};
export default TokenTransferred;

import { Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const HashFactory = ({ row, className, span }) => {
  if (span) {
    return (
      <Typography style={{ fontWeight: "bold", fontSize: 14 }} className={className}>
        {row.number}
      </Typography>
    );
  }
  return (
    <Link
      style={{ fontFamily: "Roboto Mono", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}
      className={className}
      to={"/block/" + row.number}
    >
      {row.number}
    </Link>
  );
};

export default HashFactory;

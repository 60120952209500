import React from "react";
import { Skeleton, Box, useMediaQuery } from "@mui/material";
const ListSkeleton = ({ height }) => {
  const smView = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return smView ? (
    <Box sx={{ display: "flex", flexDirection: "column", height: height - 9, justifyContent: "center" }} flex={1}>
      <Skeleton animation="wave" variant="text" width="80%" />
      <Skeleton animation="wave" variant="text" />
      <Skeleton animation="wave" variant="text" width={100} />
    </Box>
  ) : (
    <Box display="flex" alignItems="center" flex={1}>
      <Skeleton animation="wave" variant="circular" width={40} height={40} />
      <Box
        flex={1}
        sx={{ px: 2, height: height - 9, display: "flex", flexDirection: "column", justifyContent: "center" }}
      >
        <Skeleton animation="wave" variant="text" />
        <Skeleton animation="wave" variant="text" />
      </Box>
    </Box>
  );
};

export default ListSkeleton;

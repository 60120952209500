import React from "react";
import { InputBase } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/styles";
import SearchIcon from "@mui/icons-material/Search";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useInput from "hooks/useInput";
import { useSnackbar } from "notistack";
import GlobalContext from "context/GlobalContext";

const SIZE = {
  fontSize: {
    small: 13,
    medium: 16,
    large: 18,
  },
  height: {
    small: 36,
    medium: 45,
    large: 50,
  },
};

const SearchBox = ({ size = "medium" }) => {
  const searchInput = useInput("");
  const history = useHistory();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { web3 } = React.useContext(GlobalContext);
  const theme = useTheme();

  const searchOnSubmit = async (e) => {
    e.preventDefault();
    let text = searchInput.value;
    const lastestBlockNumber = await web3.eth.getBlockNumber();

    if (text.length < 12 && !isNaN(Number(text)) && Number(text) < lastestBlockNumber && Number(text) > 0) {
      return history.push("/block/" + text);
    }
    // 0xb9EcEb9F717852Ad0D936B46155cB0c0f43cBE8E
    // 컨트랙 예시
    const isHex = await web3.utils.isHex(text);

    if (!isHex) {
      return enqueueSnackbar("잘못된 입력입니다.", { variant: "error" });
    }

    const isHexStrict = await web3.utils.isHexStrict(text);

    if (!isHexStrict) {
      text = "0x" + text;
    }

    if (text.length === 42) {
      const isAddress = await web3.utils.isAddress(text);
      if (isAddress) {
        const code = await web3.eth.getCode(text);
        if (code === "0x") {
          //주소
          return history.push("/address/" + text);
        } else {
          //컨트랙
          return history.push("/address/" + text);
        }
      } else {
        return enqueueSnackbar("잘못된 주소입니다.", { variant: "error" });
      }
    } else if (text.length === 66) {
      return history.push("/tx/" + text);
    } else {
      return enqueueSnackbar("잘못된 입력입니다.", { variant: "error" });
    }
  };

  return (
    <Search style={{ height: SIZE.height[size] }} onSubmit={searchOnSubmit}>
      <SearchIconWrapper
        style={{ backgroundColor: size === "small" ? theme.palette.primary.main : theme.palette.primary.secondary }}
        type="submit"
      >
        <SearchIcon fontSize={size} />
      </SearchIconWrapper>
      <StyledInputBase
        sx={{ width: "100%", fontSize: SIZE.fontSize[size] }}
        placeholder={t("Search by Address / TX Hash/ Block / Ens")}
        inputProps={{ "aria-label": "search" }}
        value={searchInput.value}
        onChange={searchInput.onChange}
      />
    </Search>
  );
};

const Search = styled("form")(({ theme }) => ({
  position: "relative",
  border: "1px solid " + theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.common.white,
  "&:hover": {
    backgroundColor: theme.palette.common.white,
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  maxWidth: 760,
  minWidth: 240,
  height: 45,
  overflow: "auto",
  // [theme.breakpoints.up("sm")]: {
  //   width: "auto",
  // },
}));

const SearchIconWrapper = styled("button")(({ theme }) => ({
  border: 0,
  padding: theme.spacing(0, 1.5),
  height: "100%",
  position: "absolute",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.common.white,
  zIndex: 1,
  cursor: "pointer",
  "&:hover": {
    opacity: 0.7,
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(5)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    // [theme.breakpoints.up("md")]: {
    //   width: "20ch",
    // },
  },
}));

export default SearchBox;

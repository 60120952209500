import React from "react";
import {
  Card,
  CardHeader,
  Container,
  Divider,
  Grid,
  Typography,
  Box,
  Avatar,
  ListItem,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import QRCode from "qrcode.react";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { useTranslation } from "react-i18next";
import moment from "moment";
import numeral from "numeral";
import StringToColor from "string-to-color";
import qs from "qs";

import HashFactory from "components/Factory/HashFactory";
import ToFactory from "components/Factory/ToFactory";
import FromFactory from "components/Factory/FromFactory";
import Page from "components/Common/Page";
import Dialog from "components/Common/Dialog";
import FullPageLoader from "components/Common/FullPageLoader";
import FullPage404 from "components/Common/FullPage404";
import GlobalContext from "context/GlobalContext";
import abi from "resources/abi";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100% - 100px)",
  },
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  hiddenText: {
    display: "block",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));

const INITIAL_PAGE_LIMIT = 10;

const AddressDetail = ({ history, location, match }) => {
  const classes = useStyles();
  const { web3 } = React.useContext(GlobalContext);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isInvalid, setIsInvalid] = React.useState(false);
  const [info, setInfo] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [path, setPath] = React.useState(history.location);
  const initRef = React.useRef(false);
  const smView = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const { t } = useTranslation();

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  // const columns = [{ field: "id", width: 115, headerName: t("번호") }];

  const onPageMove = React.useCallback(async () => {
    const text = match.params.address;
    const body = {
      item: qs.parse(location.search, { ignoreQueryPrefix: true }).a,
      address: text,
      init: false,
      page,
      perPage: INITIAL_PAGE_LIMIT,
    };
    const {
      data: { data },
    } = await axios.post(process.env.REACT_APP_HOST + "/api/v1/token/transaction/list", body);
    setInfo((info) => ({ ...info, data }));
  }, [match.params.address, page, location.search]);

  const getTx = React.useCallback(async () => {
    const text = match.params.address;
    try {
      const isHex = await web3.utils.isHex(text);
      if (!isHex) {
        initRef.current = undefined;
        return setIsInvalid(true);
      }

      const isHexStrict = await web3.utils.isHexStrict(text);

      if (!isHexStrict) {
        return history.push(`/address/0x` + text);
      }

      const isAddress = await web3.utils.isAddress(text);

      if (!isAddress) {
        initRef.current = undefined;
        return setIsInvalid(true);
      }
      const body = {
        item: qs.parse(location.search, { ignoreQueryPrefix: true }).a,
        address: text,
        init: !initRef.current,
        page,
        perPage: INITIAL_PAGE_LIMIT,
      };
      const {
        data: { data, count, contractType },
      } = await axios.post(process.env.REACT_APP_HOST + "/api/v1/token/transaction/list", body);
      const balance = web3.utils.fromWei(await web3.eth.getBalance(text));
      const transactionCount = await web3.eth.getTransactionCount(match.params.address);
      let contract = null;
      let type = "";
      const code = await web3.eth.getCode(text);
      if (code === "0x") {
        return setIsInvalid(true);
      } else {
        try {
          type = "token";
          if (contractType === "erc-1155") {
            contract = {
              type: contractType,
              name: "ERC-1155",
              ticker: "",
              totalSupply: "",
            };
          } else {
            const caller = new web3.eth.Contract(abi, text);
            const name = await caller.methods.name().call();
            const ticker = await caller.methods.symbol().call();
            const totalSupply = await caller.methods.totalSupply().call();

            const fromWeiSupply = web3.utils.fromWei(totalSupply);

            contract = {
              type: contractType,
              name,
              ticker,
              totalSupply: fromWeiSupply < 1 ? totalSupply : numeral(fromWeiSupply).format("0, 0.[0000]"),
            };
          }
        } catch {
          return setIsInvalid(true);
        } finally {
        }
      }

      const info = {
        data,
        transactionCount,
        balance,
        type,
        count,
        contract,
      };
      initRef.current = true;
      setInfo(info);
    } catch (e) {
      initRef.current = undefined;
      return setIsInvalid(true);
    }
  }, [history, match.params.address, page, web3, location.search]);

  React.useEffect(() => {
    if (path !== history.location) {
      setPath(history.location);
      return getTx();
    }

    if (initRef.current) {
      onPageMove();
    } else {
      getTx();
      setPath(history.location);
    }
  }, [onPageMove, getTx, history.location, path]);
  return (
    <Page title={`Token`} className={classes.root}>
      <Dialog title={match.params.address} isOpen={isOpen} setIsOpen={setIsOpen}>
        <QRCode size={235} value={match.params.address} />
      </Dialog>
      {isInvalid && <FullPage404 />}
      {initRef.current === false && <FullPageLoader />}
      {initRef.current && (
        <Container className={classes.container} maxWidth={"lg"}>
          <Box display="flex" flexDirection="row" alignItems="center" mb={smView ? 1 : 2}>
            <Avatar
              sx={{
                fontSize: 14,
                width: 24,
                height: 24,
                mr: 0.5,
                backgroundColor: StringToColor(match.params.address),
              }}
            >
              {match.params.address.substring(2, 4).toUpperCase()}
            </Avatar>
            <Box display="flex" flexDirection="row" alignItems="center" mr={1}>
              <Typography fontWeight="normal" variant="h4" mr={0.5}>
                {info.type.toUpperCase()}
              </Typography>
              <Typography variant="body2">{info.contract.name}</Typography>
            </Box>
          </Box>
          <Grid spacing={2} container>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Card>
                <CardHeader title={t("개요")} titleTypographyProps={{ fontSize: 13, fontWeight: "bold" }} />
                {[
                  {
                    left: t("Token Tracker"),
                    right: `${info.contract.name} ${info.contract.ticker ? `(${info.contract.ticker})` : ""}`,
                  },
                  { left: t("Max Total Supply"), right: info.contract.totalSupply + " " + info.contract.ticker },
                ].map((item, index) => {
                  return (
                    <React.Fragment key={"overview" + index}>
                      <Divider />
                      <ListItem>
                        <ListItemText secondary={item.left}></ListItemText>
                        <ListItemText sx={{ textAlign: "right" }} secondary={item.right}></ListItemText>
                      </ListItem>
                    </React.Fragment>
                  );
                })}
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Card>
                <CardHeader title={t("추가 정보")} titleTypographyProps={{ fontSize: 13, fontWeight: "bold" }} />
                {[
                  { left: t("Contract"), right: match.params.address },
                  { left: t("Type"), right: info.contract.type.toUpperCase() },
                ].map((item, index) => {
                  return (
                    <React.Fragment key={"overview" + index}>
                      <Divider />
                      <ListItem>
                        <ListItemText secondary={item.left}></ListItemText>
                        <Box sx={{ width: smView ? 200 : "auto" }}>
                          <ListItemText
                            secondaryTypographyProps={{
                              textAlign: "right",
                              className: classes.hiddenText,
                            }}
                            secondary={item.right}
                          />
                        </Box>
                      </ListItem>
                    </React.Fragment>
                  );
                })}
              </Card>
            </Grid>
            <Grid sx={{ overflowX: "auto" }} item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid minWidth={1150}>
                <DataGrid
                  // loading={}
                  rowsPerPageOptions={[10]}
                  pagination
                  rowCount={info.count}
                  pageSize={INITIAL_PAGE_LIMIT}
                  autoHeight
                  onPageChange={handlePageChange}
                  getRowId={(row) => row.column_index}
                  paginationMode="server"
                  columns={[
                    {
                      field: "hash",
                      headerName: t("Tx Hash"),
                      flex: 1,
                      renderCell: ({ row }) => {
                        return <HashFactory row={row} />;
                      },
                    },
                    // { field: "method", headerName: t("Method"), flex: 1 },
                    {
                      field: "from",
                      headerName: t("From"),
                      flex: 1,
                      renderCell: ({ row }) => {
                        return <FromFactory className={classes.hiddenText} row={row} />;
                      },
                    },
                    {
                      field: "to",
                      headerName: t("To"),
                      flex: 1,
                      renderCell: ({ row }) => {
                        return <ToFactory className={classes.hiddenText} row={row} />;
                      },
                    },
                    {
                      field: "value",
                      headerName: info.contract.type === "erc-20" ? t("Value") : t("TokenId"),
                      flex: 1,
                      renderCell: ({ row }) => {
                        if (row._type === "erc-20") {
                        } else if (row._type === "erc-721") {
                          return row.value;
                        } else if (row._type === "erc-1155") {
                          return row.value.split("^")[0];
                        }
                      },
                    },
                    info.contract.type === "erc-1155" && {
                      field: "Value",
                      headerName: t("Value"),
                      flex: 1,
                      renderCell: ({ row }) => {
                        return row.value.split("^")[1];
                      },
                    },
                    {
                      field: "timestamp",
                      headerName: t("Timestamp"),
                      renderCell: ({ row }) => {
                        return moment(Number(`${row.timestamp}000`)).format("YYYY-MM-DD HH:mm:ss");
                      },
                      flex: 1,
                    },
                  ]}
                  rows={info.data}
                />
              </Grid>
            </Grid>
          </Grid>
        </Container>
      )}
    </Page>
  );
};

export default AddressDetail;

import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IconButton } from "@mui/material";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSnackbar } from "notistack";

const ToFactory = ({ row, className, copy = false }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const copyOnClick = () => {
    return enqueueSnackbar(t("클립보드에 복사되었습니다."), { variant: "success" });
  };

  if (row.to) {
    return (
      <React.Fragment>
        <Link style={{ fontFamily: "Roboto Mono" }} className={className} to={"/address/" + row.to}>
          {row.to}
        </Link>
        {copy && (
          <CopyToClipboard onCopy={copyOnClick} text={row.to}>
            <IconButton size="small" sx={{ ml: 0.25 }}>
              <ContentCopyOutlinedIcon sx={{ fontSize: 12 }} />
            </IconButton>
          </CopyToClipboard>
        )}
      </React.Fragment>
    );
  }

  if (row.contract_address) {
    return (
      <React.Fragment>
        <Link style={{ fontFamily: "Roboto Mono" }} className={className} to={"/address/" + row.contract_address}>
          {t("Contract Creation")}
        </Link>
      </React.Fragment>
    );
  }
};

export default ToFactory;

import React from "react";
import {
  Card,
  CardHeader,
  Container,
  Divider,
  Grid,
  Typography,
  Box,
  Avatar,
  IconButton,
  ListItem,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import QrCodeIcon from "@mui/icons-material/QrCode";
import { DataGrid } from "@mui/x-data-grid";
import { CopyToClipboard } from "react-copy-to-clipboard";
import QRCode from "qrcode.react";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import moment from "moment";
import numeral from "numeral";
import StringToColor from "string-to-color";

import HashFactory from "components/Factory/HashFactory";
import ToFactory from "components/Factory/ToFactory";
import FromFactory from "components/Factory/FromFactory";
import Page from "components/Common/Page";
import Dialog from "components/Common/Dialog";
import FullPageLoader from "components/Common/FullPageLoader";
import FullPage404 from "components/Common/FullPage404";
import GlobalContext from "context/GlobalContext";
import abi from "resources/abi";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100% - 100px)",
  },
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  hiddenText: {
    display: "block",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  linkText: {
    fontSize: 14,
  },
}));

const INITIAL_PAGE_LIMIT = 10;

const AddressDetail = ({ history, match }) => {
  const classes = useStyles();
  const { web3 } = React.useContext(GlobalContext);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isInvalid, setIsInvalid] = React.useState(false);
  const [info, setInfo] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [path, setPath] = React.useState(history.location);
  const initRef = React.useRef(false);
  const smView = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const copyOnClick = () => {
    return enqueueSnackbar(t("클립보드에 복사되었습니다."), { variant: "success" });
  };

  const qrOnClick = () => {
    setIsOpen(!isOpen);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  // const columns = [{ field: "id", width: 115, headerName: t("번호") }];

  const onPageMove = React.useCallback(async () => {
    const text = match.params.address;
    const body = { address: text, init: false, page, perPage: INITIAL_PAGE_LIMIT };
    const {
      data: { data },
    } = await axios.post(process.env.REACT_APP_HOST + "/api/v1/transaction/list", body);

    setInfo((info) => ({ ...info, data }));
  }, [match.params.address, page]);

  const getTx = React.useCallback(async () => {
    const text = match.params.address;
    try {
      const isHex = await web3.utils.isHex(text);
      if (!isHex) {
        initRef.current = undefined;
        return setIsInvalid(true);
      }

      const isHexStrict = await web3.utils.isHexStrict(text);

      if (!isHexStrict) {
        return history.push(`/address/0x` + text);
      }

      const isAddress = await web3.utils.isAddress(text);

      if (!isAddress) {
        initRef.current = undefined;
        return setIsInvalid(true);
      }

      const body = { address: text, init: !initRef.current, page, perPage: INITIAL_PAGE_LIMIT };
      const {
        data: { data, count },
      } = await axios.post(process.env.REACT_APP_HOST + "/api/v1/transaction/list", body);
      const balance = web3.utils.fromWei(await web3.eth.getBalance(text));
      const transactionCount = await web3.eth.getTransactionCount(match.params.address);
      let contract = null;
      let type = "";
      const code = await web3.eth.getCode(text);
      if (code === "0x") {
        type = "address";
      } else {
        type = "contract";

        // const { data: token } = await axios.post(process.env.REACT_APP_HOST + "/api/v1/token", body);

        // // if (token) {
        // //   contract = {
        // //     name: token.name,
        // //     ticker: token.ticker,
        // //     totalSupply: token.total_supply,
        // //   };
        // // }
        try {
          const caller = new web3.eth.Contract(abi, text);
          const name = await caller.methods.name().call();
          const ticker = await caller.methods.symbol().call();
          const totalSupply = await caller.methods.totalSupply().call();
          const fromWeiSupply = web3.utils.fromWei(totalSupply);

          contract = {
            name,
            ticker,
            totalSupply: fromWeiSupply < 1 ? totalSupply : numeral(fromWeiSupply).format("0, 0.[0000]"),
          };
        } catch {}
      }

      const info = {
        data,
        transactionCount,
        balance,
        type,
        count,
        contract,
      };
      initRef.current = true;
      setInfo(info);
    } catch (e) {
      console.log(e);
      initRef.current = undefined;
      return setIsInvalid(true);
    }
  }, [history, match.params.address, page, web3]);

  React.useEffect(() => {
    if (path !== history.location) {
      setPath(history.location);
      return getTx();
    }

    if (initRef.current) {
      onPageMove();
    } else {
      getTx();
      setPath(history.location);
    }
  }, [onPageMove, getTx, history.location, path]);

  return (
    <Page title="Address" className={classes.root}>
      <Dialog title={match.params.address} isOpen={isOpen} setIsOpen={setIsOpen}>
        <QRCode size={235} value={match.params.address} />
      </Dialog>
      {isInvalid && <FullPage404 />}
      {initRef.current === false && <FullPageLoader />}
      {initRef.current && (
        <Container className={classes.container} maxWidth={"lg"}>
          <Box display="flex" flexDirection="row" alignItems="center" mb={smView ? 1 : 2}>
            <Avatar
              sx={{
                fontSize: 14,
                width: 24,
                height: 24,
                mr: 0.5,
                backgroundColor: StringToColor(match.params.address),
              }}
            >
              {match.params.address.substring(2, 4).toUpperCase()}
            </Avatar>
            <Box display="flex" flexDirection="row" alignItems="flex-end" mr={1}>
              <Typography fontWeight="normal" variant="h4" mr={0.5}>
                {info.type.toUpperCase()}
              </Typography>
              {!smView && <Typography variant="body2">{match.params.address}</Typography>}
            </Box>
            <CopyToClipboard onCopy={copyOnClick} text={match.params.address}>
              <IconButton size="small" sx={{ backgroundColor: "rgba(119,131,143,.1)", mr: 0.5 }}>
                <ContentCopyOutlinedIcon sx={{ fontSize: 15 }} />
              </IconButton>
            </CopyToClipboard>
            <IconButton onClick={qrOnClick} size="small" sx={{ backgroundColor: "rgba(119,131,143,.1)" }}>
              <QrCodeIcon sx={{ fontSize: 15 }} />
            </IconButton>
          </Box>
          {smView && (
            <Box mb={2} sx={{ wordBreak: "break-all" }}>
              <Typography variant="body2">{match.params.address}</Typography>
            </Box>
          )}
          <Grid spacing={2} container>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <Card>
                <CardHeader title={t("개요")} titleTypographyProps={{ fontSize: 13, fontWeight: "bold" }} />
                {[
                  { left: t("Balance"), right: info.balance + " " + process.env.REACT_APP_COIN_TICKER.toUpperCase() },
                  { left: process.env.REACT_APP_COIN_TICKER.toUpperCase() + " " + t("Value"), right: "-" },
                ].map((item, index) => {
                  return (
                    <React.Fragment key={"overview" + index}>
                      <Divider />
                      <ListItem>
                        <ListItemText secondary={item.left}></ListItemText>
                        <ListItemText sx={{ textAlign: "right" }} secondary={item.right}></ListItemText>
                      </ListItem>
                    </React.Fragment>
                  );
                })}
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <Card>
                <CardHeader title={t("추가 정보")} titleTypographyProps={{ fontSize: 13, fontWeight: "bold" }} />
                {[
                  { left: t("TX Count(Nonce)"), right: info.transactionCount },
                  { left: t("Row count"), right: info.count },
                ].map((item, index) => {
                  return (
                    <React.Fragment key={"overview" + index}>
                      <Divider />
                      <ListItem>
                        <ListItemText secondary={item.left}></ListItemText>
                        <ListItemText sx={{ textAlign: "right" }} secondary={item.right}></ListItemText>
                      </ListItem>
                    </React.Fragment>
                  );
                })}
              </Card>
            </Grid>
            {info.contract && (
              <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                <Card>
                  <CardHeader title={t("계약 정보")} titleTypographyProps={{ fontSize: 13, fontWeight: "bold" }} />
                  {[
                    {
                      left: t("Token Tracker"),
                      right: `${info.contract.name} (${info.contract.ticker})`,
                      link: `/token/${match.params.address}`,
                    },
                    {
                      left: t("Total Supply"),
                      right: info.contract.totalSupply,
                    },
                  ].map((item, index) => {
                    return (
                      <React.Fragment key={"overview" + index}>
                        <Divider />
                        <ListItem>
                          <ListItemText secondary={item.left}></ListItemText>
                          {item.link ? (
                            <Link className={classes.linkText} to={item.link}>
                              {item.right}
                            </Link>
                          ) : (
                            <ListItemText sx={{ textAlign: "right" }} secondary={item.right} />
                          )}
                        </ListItem>
                      </React.Fragment>
                    );
                  })}
                </Card>
              </Grid>
            )}

            <Grid sx={{ overflowX: "auto" }} item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid minWidth={1150}>
                <DataGrid
                  // loading={}
                  rowsPerPageOptions={[10]}
                  pagination
                  rowCount={info.count}
                  pageSize={INITIAL_PAGE_LIMIT}
                  autoHeight
                  onPageChange={handlePageChange}
                  getRowId={(row) => row.column_index}
                  paginationMode="server"
                  columns={[
                    {
                      field: "hash",
                      headerName: t("Tx Hash"),
                      flex: 1,
                      renderCell: ({ row }) => {
                        return <HashFactory row={row} />;
                      },
                    },
                    // { field: "method", headerName: t("Method"), flex: 1 },
                    { field: "block_number", headerName: t("Block"), flex: 1 },
                    {
                      field: "from",
                      headerName: t("From"),
                      flex: 1,
                      renderCell: ({ row }) => {
                        return <FromFactory className={classes.hiddenText} row={row} />;
                      },
                    },
                    {
                      field: "to",
                      headerName: t("To"),
                      flex: 1,
                      renderCell: ({ row }) => {
                        return <ToFactory className={classes.hiddenText} row={row} />;
                      },
                    },
                    {
                      field: "value",
                      headerName: t("Value"),
                      flex: 1,
                      renderCell: ({ row }) => {
                        return web3.utils.fromWei(row.value) + " " + process.env.REACT_APP_COIN_TICKER.toUpperCase();
                      },
                    },
                    {
                      field: "timestamp",
                      headerName: t("Timestamp"),
                      renderCell: ({ row }) => {
                        return moment(Number(`${row.timestamp}000`)).format("YYYY-MM-DD HH:mm:ss");
                      },
                      flex: 1,
                    },
                  ]}
                  rows={info.data}
                />
              </Grid>
            </Grid>
          </Grid>
        </Container>
      )}
    </Page>
  );
};

export default AddressDetail;

import React, { forwardRef } from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import Header from "components/Common/Header";
import Footer from "components/Common/Footer";
import { Box } from "@mui/system";

const Page = forwardRef(({ children, title = "", ...rest }, ref) => {
  return (
    <div
      style={{
        height: "100%",
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
      ref={ref}
      {...rest}
    >
      <Box>
        <Helmet>
          <title>
            {process.env.REACT_APP_PROJECT_NAME} : {title}
          </title>
        </Helmet>
        <Header />
        {children}
      </Box>
      <Footer />
    </div>
  );
});

Page.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};

export default Page;

import { Dialog, DialogTitle, DialogContent, Button, DialogActions, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";

const CustomDialog = ({ isOpen, setIsOpen, title, children }) => {
  const { t } = useTranslation();
  return (
    <Dialog maxWidth="xs" fullWidth open={isOpen} onClose={() => setIsOpen(!isOpen)}>
      <DialogTitle sx={{ wordBreak: "break-all" }}>{title}</DialogTitle>
      <Divider />
      <DialogContent sx={{ display: "flex", justifyContent: "center" }}>{children}</DialogContent>
      <DialogActions>
        <Button onClick={() => setIsOpen(!isOpen)} color="primary">
          {t("닫기")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;

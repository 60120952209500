import React from "react";
import { Link } from "react-router-dom";
import { Divider, Grid, Typography, Chip, useTheme, useMediaQuery } from "@mui/material";
import moment from "moment";

import GlobalContext from "context/GlobalContext";
import FromFactory from "components/Factory/FromFactory";
import ToFactory from "components/Factory/ToFactory";
import HashFactory from "components/Factory/HashFactory";
import TokenTransferred from "components/Transaction/TokenTransferred";
import { useTranslation } from "react-i18next";
import abi from "resources/abi";
import numeral from "numeral";

const OverviewTab = ({ info, logs }) => {
  const { web3 } = React.useContext(GlobalContext);
  const [log, setLog] = React.useState(false);
  const [contract, setContract] = React.useState(false);
  const theme = useTheme();
  const smView = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const overview = [
    {
      left: "Transaction hash",
      column: ({ row }) => {
        return <HashFactory row={row} copy span />;
      },
    },
    {
      left: "Status",
      column: ({ row }) => {
        return (
          <React.Fragment>
            {row.status === -1 && <Chip size="small" variant="outlined" color="info" label="Pending" />}
            {row.status === 0 && <Chip size="small" variant="outlined" color="error" label="Failed" />}
            {row.status === 1 && <Chip size="small" variant="outlined" color="success" label="Success" />}
          </React.Fragment>
        );
      },
    },
    {
      left: "Block",
      column: ({ row }) => {
        return <Link to={"/block/" + row.block_number}>{row.block_number}</Link>;
      },
    },
    {
      left: "Timestamp",
      column: ({ row }) => {
        return <span>{moment(Number(`${row.timestamp}000`)).format("YYYY-MM-DD HH:mm:ss")} (+UTC)</span>;
      },
    },
    {
      left: "From",
      column: ({ row }) => {
        return <FromFactory row={row} copy />;
      },
    },
    {
      left: "To",
      column: ({ row }) => {
        return <ToFactory row={row} copy />;
      },
    },
    {
      left: "Token Transferred",
      column: () => {
        return <TokenTransferred log={log} contract={contract} />;
      },
    },

    {
      left: "Value",
      column: ({ row }) => {
        return (
          <span>
            {numeral(web3.utils.fromWei(row.value)).format("0,0.[00000000]")}{" "}
            {process.env.REACT_APP_COIN_TICKER.toUpperCase()}
          </span>
        );
      },
    },
    {
      left: "Gas Used",
      column: ({ row }) => {
        return numeral(row.gas_used).format("0,0");
      },
    },
    { left: process.env.REACT_APP_COIN_TICKER.toUpperCase() + " Price", column: "" },
  ];

  React.useEffect(() => {
    (async () => {
      if (logs.some((log) => log._value)) {
        const info = logs.filter((item) => item._value);

        const contracts = await Promise.all(
          info.map(async (item, index) => {
            let contract = null;

            if (item._type === "erc-1155") {
              contract = {
                name: "ERC-1155",
                ticker: "",
                totalSupply: "",
              };
            } else {
              const caller = new web3.eth.Contract(abi, item.address);
              const name = await caller.methods.name().call();
              const ticker = await caller.methods.symbol().call();
              const totalSupply = await caller.methods.totalSupply().call();
              contract = {
                name,
                ticker,
                totalSupply,
              };
            }

            return contract;
          })
        );

        setContract(contracts);
        setLog(info);
      }
    })();
  }, [logs, web3.eth.Contract]);

  return overview.map((item, index) => {
    if (!logs.some((log) => log._value) && item.left === "Token Transferred") {
      return null;
    }
    return (
      <React.Fragment key={index + "GridRow"}>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={2}
            md={2}
            lg={2}
            xl={2}
            sx={{
              wordBreak: "break-all",
              padding: theme.spacing(smView ? 0 : 1, 2),
              height: smView ? 36 : 45,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="body2">{t(item.left)}</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={10}
            md={10}
            lg={10}
            xl={10}
            sx={{
              wordBreak: "break-all",
              padding: theme.spacing(smView ? 0 : 1, 2),
              height: smView
                ? item.left === "Token Transferred"
                  ? "100%"
                  : 36
                : item.left === "Token Transferred"
                ? "100%"
                : 45,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="caption">
              {item.column === "" && "-"}
              {!item.column && ""}
              {item.column && typeof item.column === "string" && info[item.column]}
              {item.column && typeof item.column === "function" && <item.column row={info} />}
            </Typography>
          </Grid>
        </Grid>
        <Divider />
      </React.Fragment>
    );
  });
};

export default OverviewTab;

import React from "react";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  box: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  icon: {
    color: theme.palette.text.secondary,
  },
}));

const FullPage404 = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const smView = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const backHome = () => {
    history.push("/");
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.box}>
        <SentimentVeryDissatisfiedIcon className={classes.icon} sx={{ fontSize: smView ? 90 : 120 }} />
        <Typography color="text.secondary" lineHeight={1} fontWeight="bold" fontSize={smView ? 38 : 54}>
          {"404"}
        </Typography>
        <Typography color="text.secondary" fontWeight="bold" fontSize={smView ? 20 : 28}>
          {t("Page not found")}
        </Typography>
        <Button onClick={backHome} variant="outlined" size="small" sx={{ mt: 1.5 }}>
          {t("홈으로")}
        </Button>
      </Box>
    </Box>
  );
};

export default FullPage404;
